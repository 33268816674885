<template>
  <div>
    <Bar
      :chart-options="chartOptions"
      :chart-data="chartData"
      :width="300"
      :height="300"
    />
  </div>
</template>

<script>
import { Bar } from "vue-chartjs/legacy";
import {
  Chart as ChartJS,
  // Title,
  Tooltip,
  //Legend,
  // ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
ChartJS.register(
  // Title,
  Tooltip,
  //Legend,
  // ArcElement,
  CategoryScale,
  LinearScale,
  BarElement
);
export default {
  props: ["chartOptions", "chartData"],
  components: {
    Bar,
  },
  mounted() {
    this.chartData.datasets.forEach((dataset) => {
      dataset.backgroundColor = dataset.backgroundColor.map((color) => {
        if (color === undefined) {
          return "#66e259";
        } else {
          return color;
        }
      });
      // dataset.data = dataset.data.filter(value => value !== 0);
    });
    this.chartOptions.scales.x.ticks = {
      ...this.chartOptions.scales.x.ticks,
      callback: function (value) {
        const label = this.getLabelForValue(value);
        const maxLength = 10;
        return label.length > maxLength
          ? label.substr(0, maxLength) + "..."
          : label;
      },
    };
  },
  methods: {
  },
};
</script>

<style></style>
